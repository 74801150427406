@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

:root {
  --primary-color: #4eadcd;
  --secondary-color: #f24026;
  --text-color: #3c3c3c;
  --off-white: #f9f9f9;
  --primary-gray: #8b9090;
  --primary-gdray: #9a9b9b;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  color: var(--text-color);
  overflow-x: hidden;
}

ul {
  list-style: none;
}

h1 {
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #333;
}

img {
  width: 100%;
  height: auto;
}

/* Navbar */
.navbar {
  background: white;
  height: 70px;
  padding: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.nav-color {
  background: var(--off-white);
  transition: background 0.3s ease-in;
}

.navbar .flex {
  justify-content: space-between;
}

.logo {
  color: var(--primary-color);
}

.navbar ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar ul a {
  padding: 10px;
  margin: 0 5px;
  transition: opacity 0.3s ease;
}

.navbar ul a:hover {
  opacity: 0.8;
}

/* Showcase */
.showcase {
  height: 90vh;
  margin-top: 2.5rem;
}

.showcase-content h1 {
  font-weight: 700;
  font-size: 4rem;
}

.showcase-content h1,
.showcase-content p {
  margin-bottom: 20px;
}

.showcase img {
  padding-top: 2.5rem;
}

.showcase .grid {
  gap: 50px;
}

/* About (Homepage) */

.about i {
  color: var(--primary-color);
}

.about p.lead {
  margin-bottom: 2rem;
}

.about .grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.about .card-container .card {
  padding: 2.5rem 1.5rem;
  background: var(--off-white);
  transition: background 0.3s ease-in;
  height: 425px;
}

.about .card-container > .card:hover {
  background: white;
}

.about .card img {
  width: 200px;
}

.about .card h2 {
  margin: 0.5rem 0;
}

/* CTA (Homepage) */
.cta-home .grid {
  gap: 100px;
}

.cta-home h2,
.cta-home p {
  margin-bottom: 1.5rem;
}

/* Taggers and other Hero IMAGE */
.taggers,
.rent,
.contact {
  height: 60vh;
  position: relative;
  margin-top: 3rem;
}

.taggers h2,
.rent h2,
.contact h2 {
  margin-bottom: 20px;
}

/* Taggers Phoenix Tagger */
.phoenix {
  height: 800px;
}

.phoenix .container.grid {
  gap: 50px;
}

/* Taggers Hero Tagger */
.hero-tagger {
  height: 800px;
}

.hero-tagger .container.grid {
  gap: 50px;
}

/* Rent Page  */

.rent-form-section {
  background: var(--off-white);
}

.rent-form-section .rent-content {
  text-align: center;
}

.rent-form-section .rent-content i {
  color: var(--primary-color);
}

.rent-form {
  max-width: 65%;
  padding: 20px 50px;
  background: var(--primary-color);
  margin: 2rem auto;
  font-size: 1rem;
  color: white;
  border-radius: 10px;
}

.rent-form .form-control {
  margin: 20px 0;
}

.rent-form .form-control label {
  margin-bottom: 3px;
}

.rent-form .form-control label,
.rent-form .form-control small {
  display: block;
}

.rent-form .form-control small {
  margin: 4px 0;
}

.rent-form .form-control input,
.rent-form .form-control textarea,
.rent-form .form-control select {
  font-family: inherit;
  width: 90%;
  padding: 10px 10px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
}

.rent-form .form-control input:focus,
.rent-form .form-control textarea:focus {
  outline-color: var(--primary-gray);
}

/* Contact Page */
.contact-form {
  background: var(--primary-gray);
}

.contact-form .form-control input:focus,
.contact-form .form-control textarea:focus {
  outline-color: var(--primary-color);
}

/* Footer */

.footer .footer-sections {
  display: none;
}

.footer-bottom.flex {
  justify-content: space-between;
}

.footer-bottom .social a {
  margin: 0 10px;
  transition: color 0.3s ease-in;
}

.footer-bottom .social a:hover {
  color: var(--off-white);
}

/* MEDIA QUERIES */
/* Tablet */
@media (max-width: 860px) {
  .navbar {
    height: 150px;
    text-align: center;
  }
  .navbar .flex {
    flex-direction: column;
  }
  .showcase .grid,
  .about .grid,
  .cta-home .grid {
    grid-template-columns: 1fr;
  }

  /* showcase */
  .showcase {
    text-align: center;
  }
  .showcase img {
    display: none;
  }

  /* about homepage */
  .about i {
    color: var(--primary-color);
  }

  /* cta homepage */
  .cta-home {
    text-align: center;
  }

  /* Taggers page */
  .phoenix,
  .hero-tagger {
    height: 1100px;
  }

  .phoenix img,
  .hero-tagger img {
    width: 70%;
    justify-self: center;
  }

  .hero-tagger .grid,
  .phoenix .grid,
  .rent .grid {
    grid-template-columns: 1fr;
  }

  .taggers.hero-banner img,
  .rent.hero-banner img,
  .contact.hero-banner img {
    justify-self: center;
    position: absolute;
    width: 70%;
    right: 0%;
    top: 70%;
    z-index: -1;
    transform: scaleX(-1);
  }

  /* Rent page */
  .rent-form {
    max-width: 90%;
  }
}

/* Mobile */
@media (max-width: 500px) {
  /* Utility */
  .hero-banner {
    height: 60vh;
    position: relative;
    margin-top: 7rem;
  }

  /* Navbar */
  .navbar {
    height: 175px;
  }
  .navbar .flex {
    justify-content: center;
  }

  /* showcase */
  .showcase {
    height: 70vh;
    line-height: 1.2;
    margin-top: 200px;
    text-align: center;
  }

  /* .navbar h1 {
    text-align: center;
  } */

  /* Taggers page */

  .phoenix .container.grid,
  .hero-tagger .container.grid {
    gap: 0px;
  }

  .phoenix img,
  .hero-tagger img {
    width: 100%;
  }

  .taggers.hero-banner img,
  .rent.hero-banner img,
  .contact.hero-banner img {
    display: none;
  }

  /* Rent page */
  /* Rent page */
  .rent-form {
    max-width: 100%;
    padding: 20px 30px;
  }

  /* Iphone 6 */
  @media (max-width: 380px) and (max-height: 680px) {
    .about .container {
      padding-top: 90px;
    }
  }
}
